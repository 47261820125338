<template>
  <div>
    <a-select
      :manual-doc="userDoc"
      field="routes.parts.where.supplier"
      :label="$t('suppliers.sg')"
      options="suppliers"
      null-option
      @change="(value) => updateAndFetch('supplier', value)"
    />
  </div>
</template>

<script>
import { keys } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

const orderByOptions = {
  'created_at,desc': 'Last created'
}

export default {
  data () {
    return {
      orderByOptions
    }
  },
  computed: {
    userDoc () {
      return { module: 'user' }
    },
    where () {
      return this.user.routes.parts.where
    }
    /*availableVehicles () {
      return _.filter(this.vehicles, vehicle => vehicle.customer.id === this.where.failure.customer.id)
    }*/
  },
  created () {
    /*if (this.user.role == 'customer' && (this.where.failure.customer.id != this.user.customer.id)) {
      this.updateCustomer(this.user.customer)
    } else {
      if (this.where.failure.customer) {
        this.$store.dispatch('vehicles/fetchAndAdd', { where: [['customer.id', '==', this.where.failure.customer.id]],
          limit: 0 })
      }
    }*/
  },
  methods: {
    keys,
    /*async updateCustomer (value) {
      if (value) {
        await this.$store.dispatch('vehicles/fetchAndAdd', { where: [['customer.id', '==', value.id]], limit: 0 })
      }

      await this.$store.dispatch('user/update', {
        path: `routes.failureParts.where.failure.vehicle`,
        value: null
      })
      this.updateAndFetch('failure.customer', value)
    },*/
    async updateAndFetch (prop, value) {
      await this.$store.dispatch('user/update', {
        path: `routes.parts.where.${prop}`,
        value
      })

      this.$store.dispatch('parts/fetchDocs')
    }
  }
}
</script>
